import {Component, ViewChild} from '@angular/core';
import {FullCalendarComponent} from "@fullcalendar/angular";
import {SwUpdate} from "@angular/service-worker";

@Component({
  selector: 'appRoot',
  templateUrl: './app.component.html'
})
export class AppComponent {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  title = 'app';

  constructor(update: SwUpdate) {
    // Checks if the update is enabled
    if (!update.isEnabled) {
      return;
    }
    update.versionUpdates.subscribe((event) => {
      // New method
      if (event.type === 'VERSION_DETECTED') {
        if (confirm('New update available, please reload page')) {
          update.activateUpdate().then(() => location.reload());
        }
      }
    });
  }
}
