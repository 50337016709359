import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Appointment} from '../models/appointment';

@Injectable({
  providedIn: 'root'
})

export class ReportService {
  constructor(private http: HttpClient) {
  }

  downloadReport(search) {
    return this.http.post('api/report', search).pipe(
      map((data: any) => {
        return data;
      }));
  }
}

