import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavbarComponent} from './core/navbar/navbar.component';
import {LoginComponent} from './core/login/login.component';
import {AppRoutingModule} from './app-routing.module';
import {MaterialModule} from './shared/material/material.module';
import {CommonModule, DatePipe} from '@angular/common';
import {IndexComponent} from './modules/index/index.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {GlobalFunctionsService} from './shared/services/globalFunctions.service';
import {CustomValidators} from './shared/services/custom.validators';
import {AuthInterceptor} from './core/auth/auth.interceptor';
import {MatConfirmDialogComponent} from './shared/dialogs/matConfirmDialog/matConfirmDialog.component';
import {EmployeePasswordComponent} from './core/employeeSettings/password/employeePassword.component';
import {ToastrModule} from 'ngx-toastr';
import {EmployeeViewComponent} from './modules/administration/emplyoee/employeeView/employeeView.component';
import {EmployeeComponent} from './modules/administration/emplyoee/employee/employee.component';
import {DanishDatePipe} from './shared/pipe/danishDate';
import {DanishCurrencyPipe} from './shared/pipe/danishCurrency';
import {CalendarComponent} from './modules/calendar/calendar.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import {DatePicker} from './core/date.picker';
import {IndexDialogComponent} from './modules/index/dialog/indexDialog.component';
import {AdminComponent} from './modules/administration/admin/admin.component';
import {ReportComponent} from './modules/report/report.component';
import {ServiceWorkerModule} from "@angular/service-worker";
import {environment} from "../environments/environment";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    IndexComponent,
    IndexDialogComponent,
    DatePicker,
    DanishDatePipe,
    DanishCurrencyPipe,
    CalendarComponent,
    EmployeeViewComponent,
    EmployeeComponent,
    MatConfirmDialogComponent,
    EmployeePasswordComponent,
    AdminComponent,
    ReportComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    FullCalendarModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    CommonModule,
    NgbModule,
    ToastrModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 5 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:5000",
    }),
  ],
  providers: [CustomValidators, GlobalFunctionsService, DatePipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent],
  exports: [DatePicker],
})
export class AppModule {
}
