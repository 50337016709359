import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'timeCreateDialog',
  templateUrl: 'indexDialog.component.html',
})

export class IndexDialogComponent {
  marked = false;
  public date = this.data.date;
  public duration = this.data.duration;
  public appointment = this.data.appointment;

  // Used for displaying in a dialog ex calendar
  toggleVisibility(e) {
    this.marked = e.target.checked;
  }

  constructor(private dialogRef: MatDialogRef<IndexDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data) {
  }

  receiveMessage($event) {
    this.dialogRef.close(this);
  }
}
