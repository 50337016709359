import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthenticationService} from './authenticationService';
import {first} from 'rxjs/operators';
import {GlobalFunctionsService} from '../../shared/services/globalFunctions.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService, private globalFunctionsService: GlobalFunctionsService) {
  }

  getExpires() {
    const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
    return token.Expires;
  }

  // Convert milliseconds to hours
  convertMilliToHours(time) {
    return time / 1000 / 60 / 60;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    // Check if logged in
    if (localStorage.getItem('token') != null) {
      const currentDatetime = new Date();
      const expiresDatetime = new Date(this.getExpires());
      const difference = this.convertMilliToHours((expiresDatetime.getTime() - currentDatetime.getTime()));
      // Refresh token if under x hours left
      if (difference < 3) {
        this.authenticationService.renew()
          .pipe(first())
          .subscribe(
            () => {
            },
            error => {
              console.log(error);
            });
      }
      return true;
    }

    // Not logged in so redirect to login page
    this.router.navigate(['/login']);
    return false;
  }
}
