import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {ProjectService} from '../../../shared/services/project.service';
import {GlobalFunctionsService} from '../../../shared/services/globalFunctions.service';
import {CustomValidators} from '../../../shared/services/custom.validators';
import {AppointmentService} from '../../../shared/services/appointment.service';
import {LockService} from '../../../shared/services/lock.service';
import {Lock} from '../../../shared/models/lock';
import {Search} from '../../../shared/models/search';
import {ReportService} from '../../../shared/services/report.service';

@Component({
  selector: 'admin',
  templateUrl: 'admin.component.html',
  styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit {
  constructor(private projectService: ProjectService, public globalFunctionsService: GlobalFunctionsService, public toastrService: ToastrService,
              private fb: FormBuilder, public customVal: CustomValidators, public appointmentService: AppointmentService, private datePipe: DatePipe,
              private lockService: LockService, private reportService: ReportService) {
  }

  public employeeId = GlobalFunctionsService.getEmployeeId();
  public randomName = GlobalFunctionsService.randomName;
  public locks: Lock[] = [];
  public lock;
  public locked: boolean;

  public searchForm = this.fb.group({
    startDate: ['', [Validators.required]],
    endDate: ['', [Validators.required]],
  });

  formErrors = {
    'startDate': '',
    'endDate': '',
  };

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.lockService.getLocks().subscribe(
      (success) => {
        this.locks = success;

        // Find the next month there is not lock
        const date = new Date(this.datePipe.transform(this.locks[this.locks.length - 1].date, 'yyyy-MM-dd'));
        const date2 = new Date(date.setMonth(date.getMonth() + 1)).toISOString();
        this.lock = new Date(this.datePipe.transform(date2, 'yyyy-MM-dd')).toISOString();
      },
      err => {
        console.log(err);
      }
    );
  }

  toggleMonth(lock: Lock = null) {
    this.locked = true;
    if (lock == null) {
      const lock1: Lock = {
        lockId: 0,
        modifiedBy: this.employeeId,
        modifiedOn: new Date(),
        lockBy: this.employeeId,
        lockOn: new Date(),
        date: this.lock
      };

      this.lockService.insertLock(lock1).subscribe(
        () => {
          this.load();
          this.locked = false;
        },
        err => {
          console.log(err);
          this.locked = false;
        }
      );
    } else {
      if (lock.lockBy == null) {
        lock.lockBy = this.employeeId;
        lock.lockOn = new Date();
      } else {
        lock.lockBy = null;
        lock.lockOn = null;
      }
      this.lockService.updateLock(lock).subscribe(
        () => {
          this.load();
          this.locked = false;
        },
        err => {
          console.log(err);
          this.locked = false;
        }
      );
    }
  }

  download() {
    this.customVal.submitClicked = true;
    this.customVal.logValidationErrors(this.searchForm, this.formErrors);
    if (this.searchForm.valid) {

      const search: Search = {
        startDate: new Date(this.searchForm.value.startDate),
        endDate: new Date(this.searchForm.value.endDate),
        employeeId: 0,
      };
      this.reportService.downloadReport(search).subscribe(
        (success) => {
          this.downloadFile(success.file, success.name);
          this.locked = false;
        },
        err => {
          console.log(err);
          this.locked = false;
        }
      );
    }
  }

  downloadFile(file, name) {
    const downloadLink = document.createElement('a');
    downloadLink.href = file;
    downloadLink.download = name;
    downloadLink.click();
  }
}

