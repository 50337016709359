import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {GlobalFunctionsService} from '../../shared/services/globalFunctions.service';
import {ReportService} from '../../shared/services/report.service';
import {CustomValidators} from '../../shared/services/custom.validators';
import {ProjectService} from '../../shared/services/project.service';
import {Search} from "../../shared/models/search";
import {Router} from "@angular/router";

@Component({
  selector: 'report',
  templateUrl: 'report.component.html',
  styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit {
  constructor(private router: Router, private projectService: ProjectService, public globalFunctionsService: GlobalFunctionsService, public toastrService: ToastrService,
              private fb: FormBuilder, public customVal: CustomValidators, private reportService: ReportService) {
    if(this.globalFunctionsService.getEmployeeGroupId() == 3){
      this.toastrService.error("Du har ikke adgang til denne side.")
      this.router.navigate(['']);
    }
  }

  public employeeId = GlobalFunctionsService.getEmployeeId();
  public randomName = GlobalFunctionsService.randomName;
  public locked;
  public searchForm = this.fb.group({
    startDate: ['', [Validators.required]],
    endDate: ['', [Validators.required]],
  });

  formErrors = {
    'startDate': '',
    'endDate': '',
  };

  ngOnInit(): void {
  }


  download() {
    this.customVal.submitClicked = true;
    this.customVal.logValidationErrors(this.searchForm, this.formErrors);
    if (this.searchForm.valid) {
      const search: Search = {
        startDate: new Date(this.searchForm.value.startDate),
        endDate: new Date(this.searchForm.value.endDate),
        employeeId: this.employeeId
      };
      this.reportService.downloadReport(search).subscribe(
        (success) => {
          this.downloadFile(success.file, success.name);
          this.locked = false;
        },
        err => {
          console.log(err);
          this.locked = false;
        }
      );
    }
  }

  downloadFile(file, name) {
    const downloadLink = document.createElement('a');
    downloadLink.href = file;
    downloadLink.download = name;
    downloadLink.click();
  }
}

