import {Component, HostListener, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {GlobalFunctionsService} from '../../shared/services/globalFunctions.service';
import {DialogService} from '../../shared/dialogs/dialog.service';
import {Appointment} from '../../shared/models/appointment';
import {Employee} from '../../shared/models/employee/employee';
import {DatePipe} from '@angular/common';
import {EmployeeService} from '../../shared/services/employee.service';
import {AppointmentService} from '../../shared/services/appointment.service';
import {AppointmentCalendar} from '../../shared/models/appointmentCalendar';
import * as moment from 'moment';
// import {CalendarOptions, EventApi} from '@fullcalendar/angular';
import { CalendarOptions, EventApi } from '@fullcalendar/core';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {filter} from 'rxjs/operators';
import {IndexDialogComponent} from '../index/dialog/indexDialog.component';
import {LockService} from '../../shared/services/lock.service';
import {TimeUsed} from '../../shared/models/timeUsed';
import {CopyAppointment} from '../../shared/models/copyAppointment';
import {Router} from "@angular/router";
import {CalendarOption} from "@fullcalendar/angular/private-types";
import dklocale from '@fullcalendar/core/locales/da';
import enlocale from '@fullcalendar/core/locales/en-gb';
import selocale from '@fullcalendar/core/locales/sv';
import nolocale from '@fullcalendar/core/locales/nn';

@Component({
  selector: 'calendar',
  templateUrl: 'calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})

export class CalendarComponent implements OnInit {
  constructor(private router: Router, public dialog: MatDialog, private datePipe: DatePipe, private appointmentService: AppointmentService,
              public globalFunctionsService: GlobalFunctionsService, private employeeService: EmployeeService,
              private toastrService: ToastrService, private dialogService: DialogService, private lockService: LockService
  ) {
    if(this.globalFunctionsService.getEmployeeGroupId() == 3){
      this.toastrService.error("Du har ikke adgang til denne side.")
      this.router.navigate(['']);
    }
  }
  locales = [dklocale, enlocale, selocale,nolocale];

  appointments: Appointment[] = [];
  employees: Employee[] = [];
  temp: AppointmentCalendar[] = [];
  chosenEmployeeId = GlobalFunctionsService.getEmployeeId();
  employeeId = GlobalFunctionsService.getEmployeeId();
  employeeGroupId = GlobalFunctionsService.getEmployeeGroupId();
  lockedMonth = false;
  timeUsed = [0, 0, 0, 0, 0, 0, 0, 0, 0];
  chosenElement;
  chosenField;
  copyElement;

  calendarOptions: CalendarOptions = {
    locales: this.locales,
    locale: dklocale,
    eventTimeFormat: {hour12: false, hour: '2-digit', minute: '2-digit'},
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin, // needed for dateClick
    ],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    initialView: 'timeGridWeek',
    nowIndicator: true,
    slotDuration: '00:15:00',
    slotLabelInterval: '01:00:00',
    scrollTime: '07:30:00',
    weekNumbers: true,
    navLinks: true,
    selectable: true,
    eventTextColor: '#fff',
    aspectRatio: 1.65,
    firstDay: 1,
    longPressDelay: 500,
    eventResizableFromStart: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventDrop:this.handleEventDrop.bind(this),
    datesSet: this.handleDatesRender.bind(this),
    eventResize: this.handleEventResize.bind(this)
  };
  dateSearch;
  currentEvents: EventApi[] = [];

  @HostListener('document:keydown.control.c', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
    if (this.chosenElement !== undefined) {
      this.toastrService.success('Copy', 'Success');
      this.copyElement = this.chosenElement;
    } else {
      this.toastrService.warning('Copy', 'Nothing to copy');
    }
  }

  @HostListener('document:keydown.control.v', ['$event']) onKeydownHandler1(evt: KeyboardEvent) {
    if (this.copyElement !== undefined) {
      if (this.chosenField !== undefined) {
        this.copyAppointment(this.copyElement, this.chosenField);
      } else {
        this.toastrService.warning('Paste', 'Click where to paste');
      }
    } else {
      this.toastrService.warning('Paste', 'Nothing to paste');
    }
  }

  @HostListener('document:keydown.delete', ['$event']) onKeydownHandler2(evt: KeyboardEvent) {
    if (this.chosenElement !== undefined) {
      /*this.toastrService.success('Copy', 'Success');
      this.copyElement = this.chosenElement;*/

      if (this.dialogService.modalOpen === false) {
        this.dialogService.modalOpen = true;
        this.dialogService.openConfirmDialog('Are you sure you want to delete time registration')
          .afterClosed().subscribe(res => {
          if (res) {
            this.deleteAppointment(this.chosenElement);
          }
          this.dialogService.modalOpen = false;
        });
      }

    } else {
      this.toastrService.warning('Delete', 'Nothing to delete');
    }
  }


  ngOnInit() {
    this.dateSearch = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
    this.employeeService.getEmployees()
      .subscribe(success => {
        if (success) {
          this.employees = success;
        }
      });
  }

  loadEvents(employeeId) {
    this.lockService.getSpecificMonth(this.dateSearch).subscribe(
      success => {
        if (success?.lockBy != null && GlobalFunctionsService.getEmployeeGroupId() !== 1) {
          this.lockedMonth = true;
        } else {
          this.lockedMonth = false;
        }
      });

    this.temp.length = 0;
    // Appointment
    this.appointmentService.getAllAppointmentsCalendarForEmployee(this.chosenEmployeeId, this.dateSearch)
      .subscribe(success => {
        this.getTimeUsed(this.dateSearch);
        if (success) {
          this.appointments = success;
          for (const row of this.appointments) {
            let editable = false;
            let color;
            if (row.state === 0 && row.employeeId === this.employeeId) {
              editable = true;
            } else {
              editable = false;
            }
            if (row.duration > 0) {
              let title = '';

              if (row.customerId !== 1) {
                title += row.customer.name + ' - ';
              }

              const originalDate = moment(row.date.toString(), 'YYYY-MM-DDThh:mm:ss');
              const startDate = originalDate.subtract((((row.duration) * 0.6) * 100), 'minutes').format('YYYY-MM-DDTHH:mm:ssZ');
              if (row.categoryId === 1) {
                color = this.globalFunctionsService.colorProject;
                title += row.project.number;
              } else if (row.category.categoryParentId === 20) {
                color = this.globalFunctionsService.colorAbsence;
              } else if (row.category.categoryParentId === 16) {
                color = this.globalFunctionsService.colorAdministrator;
              } else {
                color = this.globalFunctionsService.colorByTheWay;
              }

              if (row.categoryId !== 1) {
                title += row.category.name;
              }

              this.temp.push({
                id: row.appointmentId.toString(),
                title: title + '\n' + row.description,
                start: startDate,
                end: row.date.toString(),
                color: color,
                droppable: editable,
                durationEditable: editable,
                editable: editable,
                eventTextColor: '#fff'
              });
            }
          }
          this.calendarOptions.events = this.temp;
        }
      });
  }

  // Employee change
  onEmployeeChange(newValue) {
    this.chosenEmployeeId = newValue.target.value;
    this.loadEvents(newValue.target.value);
  }

  handleDateSelect(arg) {
    if (this.chosenField === arg.endStr || this.copyElement === undefined) {
      if (this.lockedMonth) {
        this.toastrService.warning('Month is locked', 'Time registration');
      } else {
        const time = (((arg.end - arg.start) / 1000) / 60) / 60;
        this.onTimeModalCreate(arg.endStr, time.toString());
      }
      this.chosenField = undefined;
    } else {
      this.chosenField = arg.endStr;
    }
  }

  handleEventDrop(arg) {
    if (this.lockedMonth) {
      this.toastrService.warning('Month is locked', 'Time registration');
    } else {
      const split = arg.event.id.split('-');
      const app = this.appointments.find(obj => obj.appointmentId === Number(split[0]));
      app.date = GlobalFunctionsService.removeTimezoneFromDateTime(arg.event.end);
      app.modifiedOn = new Date();
      app.modifiedBy = this.employeeId;
      this.appointmentService.updateAppointment(app).subscribe(
        () => {
          this.toastrService.success('Moved time registration', 'Time registration');
          this.loadEvents(this.chosenEmployeeId);
        },
        err => {
          this.toastrService.error(err.error.message, err.error);
          console.log(err);
          this.loadEvents(this.chosenEmployeeId);
        }
      );
    }
  }

  handleEventResize(arg) {
    if (this.lockedMonth) {
      this.toastrService.warning('Month is locked', 'Time registration');
    } else {
      const split = arg.event.id.split('-');
      const app = this.appointments.find(obj => obj.appointmentId === Number(split[0]));
      app.duration = (((arg.event.end - arg.event.start) / 1000) / 60) / 60;
      app.date = GlobalFunctionsService.removeTimezoneFromDateTime(arg.event.end);
      app.modifiedOn = new Date();
      app.modifiedBy = this.employeeId;

      this.appointmentService.updateAppointment(app).subscribe(
        () => {
          this.toastrService.success('Updated time registration', 'Time registration');
          this.loadEvents(this.chosenEmployeeId);
        },
        err => {
          this.loadEvents(this.chosenEmployeeId);
          this.toastrService.error(err.error.message, err.error);
          console.log(err);
        }
      );
    }
  }

  handleEventClick(arg) {
    if (this.chosenElement === arg.event.id) {
      const split = arg.event.id.split('-');
      const app = this.appointments.find(obj => obj.appointmentId === Number(split[0]));
      this.onTimeEditModalCreate(app);
      this.chosenElement = null;
    } else {
      this.chosenElement = arg.event.id;
    }
  }

  onTimeEditModalCreate(app) {
    this.dialog.open(IndexDialogComponent, {
      data: {
        appointment: app,
      },
      maxHeight: '95vh'
    }).afterClosed().pipe(
      filter(person => person)
    ).subscribe(() => {
      this.loadEvents(this.chosenEmployeeId);
    });
  }

  onTimeModalCreate(date, duration) {
    this.dialog.open(IndexDialogComponent, {
      data: {
        duration: duration,
        date: date,
      },
      maxHeight: '95vh'
    }).afterClosed().pipe(
      filter(person => person)
    ).subscribe(() => {
      this.loadEvents(this.chosenEmployeeId);
    });
  }

  handleDatesRender(event: any) {
    this.dateSearch = this.datePipe.transform(event.view.currentStart, 'yyyy-MM-dd');
    this.loadEvents(this.chosenEmployeeId);
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

  private getTimeUsed(dateSearch) {
    const timeUsed: TimeUsed = {
      employeeId: this.chosenEmployeeId,
      day: dateSearch,
      week: true
    };

    this.appointmentService.getTimeUsedForEmployee(timeUsed).subscribe(
      success => {
        this.timeUsed = success;
      });
  }

  copyAppointment(id, time) {
    const copyAppointment1: CopyAppointment = {
      appointmentId: id,
      time: time
    };

    this.appointmentService.copyAppointment(copyAppointment1).subscribe(
      () => {
        this.toastrService.success('Paste', 'Success');
        this.loadEvents(this.chosenEmployeeId);
      });
  }

  deleteAppointment(appointmentId) {
    this.appointmentService.deleteAppointment(appointmentId).subscribe(
      () => {
        this.toastrService.success('Deleted successfully', 'Success');
        this.loadEvents(this.chosenEmployeeId);
      });
  }
}
