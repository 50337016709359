import {NgModule} from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import {LoginComponent} from './core/login/login.component';
import {IndexComponent} from './modules/index/index.component';
import {AuthGuard} from './core/auth/auth.guard';
import {EmployeePasswordComponent} from './core/employeeSettings/password/employeePassword.component';
import {EmployeeViewComponent} from './modules/administration/emplyoee/employeeView/employeeView.component';
import {CalendarComponent} from './modules/calendar/calendar.component';
import {AdminComponent} from './modules/administration/admin/admin.component';
import {AuthGuardAdmin} from './core/auth/auth.guardAdmin';
import {ReportComponent} from './modules/report/report.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'updateUserPassword', component: EmployeePasswordComponent, canActivate: [AuthGuard]},
  {path: 'administrationEmployee', component: EmployeeViewComponent, canActivate: [AuthGuardAdmin]},
  {path: 'administration', component: AdminComponent, canActivate: [AuthGuardAdmin]},
  {path: '', component: IndexComponent, canActivate: [AuthGuard]},
  {path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard]},
  {path: 'report', component: ReportComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
