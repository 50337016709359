import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Project} from '../models/project';

@Injectable({
  providedIn: 'root'
})

export class ProjectService {
  constructor(private http: HttpClient) {
  }

  getProjects(){
    return this.http.get('/api/Project')
      .pipe(
        map((data: Project[]) => {
          return data;
        }));
  }
  getProjectsWithVKST(){
    return this.http.get('api/Project/VKST').pipe(
      map((data:Project[]) => {
        return data
      })
    )
  }
}
