import {Component} from '@angular/core';
import {GlobalFunctionsService} from '../../shared/services/globalFunctions.service';
import {DialogService} from '../../shared/dialogs/dialog.service';
import {NavigationStart, Router} from '@angular/router';
import {AuthenticationService} from '../auth/authenticationService';
import {MatDialog} from '@angular/material/dialog';
import {EmployeeService} from '../../shared/services/employee.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {
  constructor(public globalFunctionsService: GlobalFunctionsService, private dialogSrv: DialogService,
              private router: Router, private authenticationService: AuthenticationService, private dialog: MatDialog,
              private employeeService: EmployeeService
  ) {
    this.loggedIn = !!localStorage.getItem('token');
    this.getEmployees();
    authenticationService.tokenValue.subscribe((nextValue) => {
      this.loggedIn = nextValue !== '';
    });
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        event.url = event.url.split('?')[0];
        this.currentRoute = event.url.substr(1);
      }
    });
  }

  isExpanded = false;
  loggedIn = false;
  currentRoute = '';

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  onLogout() {
    if (this.dialogSrv.modalOpen === false) {
      this.dialogSrv.modalOpen = true;
      this.dialogSrv.openConfirmDialog('Are you sure you want to log off?', 'Log out', 'Log out', 'Cancel')
        .afterClosed().subscribe(res => {
        if (res) {
          this.authenticationService.token = '';
          this.router.navigateByUrl('/login');
        }
        this.dialogSrv.modalOpen = false;
      });
    }
  }

  getEmployees() {
    this.employeeService.getEmployees()
      .subscribe(success => {
        GlobalFunctionsService.employees = success;
      });
  }

  openUserAdministration() {
    this.router.navigateByUrl('/administrationEmployee');
  }
}
