<header *ngIf="loggedIn">
  <nav
    class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow fixed-top container-fluid ps-0">
    <div class="container-fluid p-0">
      <a class="navbar-brand" [routerLink]="['/']"><img src="../../../assets/img/logo.png" alt="" style="max-width: 100%; padding: 0px 16px"></a>
      <button class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded"
              (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex me-auto" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow itop-navbar-center">
          <!-- Links -->
          <li class="nav-item" [ngClass]="{'active' : currentRoute == ''}">
            <a class="nav-link" (click)="isExpanded=false;" [routerLink]="['/']">Time registration</a>
          </li>
          <li [hidden]="globalFunctionsService.getEmployeeGroupId() == 3" class="nav-item" [ngClass]="{'active' : currentRoute == 'calendar'}">
            <a class="nav-link" (click)="isExpanded=false;" [routerLink]="['calendar']">Calendar</a>
          </li>
          <li [hidden]="globalFunctionsService.getEmployeeGroupId() == 3" class="nav-item" [ngClass]="{'active' : currentRoute == 'report'}">
            <a class="nav-link" (click)="isExpanded=false;" [routerLink]="['report']">Report</a>
          </li>
          <li class="nav-item" *ngIf="globalFunctionsService.getEmployeeGroupId() == 1"
              [ngClass]="{'active' : currentRoute == 'administration'}">
            <a class="nav-link" (click)="isExpanded=false;" [routerLink]="['administration']">Administration</a>
          </li>
        </ul>
      </div>

      <ul class="navbar-nav ms-auto float-end">
        <!-- Administrator -->
        <li *ngIf="globalFunctionsService.getEmployeeGroupId() == 1" class="nav-item dropdown">
          <a class="nav-link"
             (click)="openUserAdministration();" role="button"
             data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-users-cog fa-2x text-primary itop-hover"></i>
          </a>
        </li>

        <!-- User -->
        <li class="nav-item dropdown" ngbDropdown display="dynamic" placement="bottom-right">
          <!-- dropdown-toggle text-white-->
          <a class="nav-link dropdown-toggle"
             ngbDropdownToggle
             id="navbarDropdown"
             data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-user fa-2x text-primary"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right py-2" [ngbCollapse]="!isExpanded"
               ngbDropdownMenu
               aria-labelledby="navbarDropdown">
            <div class="container h-25">
              <div class="row">
                <div class="col-sm-12">
                  <p class="grayed-out-text">{{globalFunctionsService.getEmployeeUsername()}}</p>
                </div>
              </div>
            </div>
            <a class="dropdown-item userCustomDropdown" routerLink="updateUserPassword" (click)="isExpanded=false;">
              <i class="fas fa-cogs"></i> Change password
            </a>
            <a class="dropdown-item userCustomDropdown" (click)="onLogout(); isExpanded=false;">
              <i class="fas fa-power-off"></i> Log out
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
