<div class="container pb-3">
  <div class="row">
    <div class="col mb-4">
      <div class="pageTitle d-flex align-items-center">
        <h1 class="mb-0">Update password</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-body">
        <form [formGroup]='employeePasswordForm' class="standardModalForm" (ngSubmit)="onSubmit()"
              autocomplete="off">
          <div class="form-row">
            <div class="form-group col-md-6" [ngClass]="{'has-error' : formErrors.password}">
              <label for="password">Password:</label>
              <input type="password" class="form-control" formControlName="password" name="{{randomName}}"
                     id="password" autocomplete="off" tabindex="2"
                     (blur)="customVal.logValidationErrors(this.employeePasswordForm, formErrors)">
              <span class="help-block alert-danger" *ngIf="formErrors.password">
                                {{formErrors.password}}
                            </span>
            </div>
            <div class="form-group col-md-6" [ngClass]="{'has-error' : formErrors.password2}">
              <label for="password2">Repeat password:</label>
              <input type="password" class="form-control" formControlName="password2"
                     name="{{randomName}}"
                     id="password2"
                     autocomplete="off" tabindex="2"
                     (blur)="customVal.logValidationErrors(this.employeePasswordForm, formErrors)">
              <span class="help-block alert-danger" *ngIf="formErrors.password2">
                                {{formErrors.password2}}
                            </span>
            </div>
          </div>
          <div class="form-row">
            <div class="col-xs-2">
              <button type="submit" class="btn btn-primary btn-block" tabindex="9" id="submit">
                Update
                password
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
