import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Employee} from '../models/employee/employee';
import {EmployeeGroup} from '../models/employee/employeeGroup';

@Injectable({
  providedIn: 'root'
})

export class EmployeeService {
  constructor(private http: HttpClient) {
  }

  getEmployees() {
    return this.http.get('/api/employee')
      .pipe(
        map((data: Employee[]) => {
          return data;
        }));
  }

  insertEmployee(employee) {
    return this.http.post('api/employee', employee).pipe(
      map((data: Employee) => {
        return data;
      }));
  }

  updateEmployee(employee) {
    return this.http.put('api/employee', employee).pipe(
      map((data: Employee) => {
        return data;
      }));
  }

  disableEmployee(employee) {
    return this.http.delete('api/employee/' + employee.employeeId);
  }

  getEmployeeGroup() {
    return this.http.get('/api/employee/employeeGroup')
      .pipe(
        map((data: EmployeeGroup[]) => {
          return data;
        }));
  }
}
