import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Category} from '../models/category';

@Injectable({
  providedIn: 'root'
})

export class CategoryService {
  constructor(private http: HttpClient) {
  }

  getCategory(sick = true) {
    return this.http.get('/api/category/' + sick)
      .pipe(
        map((data: Category[]) => {
          return data;
        }));
  }

  getCategoryById(categoryId) {
    return this.http.get('/api/category/trace/' + categoryId)
      .pipe(
        map((data: Category) => {
          return data;
        }));
  }
}
