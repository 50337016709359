import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../auth/authenticationService';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService, private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.authenticationService.getLocalIp();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.authenticationService.login(this.loginForm.value.username, this.loginForm.value.password, this.authenticationService.Ipv4)
        .pipe(first())
        .subscribe(
          () => {
            this.router.navigate(['']);
          },
          () => {
            this.toastrService.error('Username or password is wrong', 'Login');
          });
    } else {
      this.toastrService.warning('Fill out username and password', 'Login');
    }
  }
}
