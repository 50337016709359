import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Appointment} from '../models/appointment';

@Injectable({
  providedIn: 'root'
})

export class AppointmentService {
  constructor(private http: HttpClient) {
  }

  getAppointment() {
    return this.http.get('/api/appointment')
      .pipe(
        map((data: Appointment[]) => {
          return data;
        }));
  }

  getAllAppointmentsCalendarForEmployee(employeeId: number, dateSearch) {
    return this.http.get('/api/appointment/calendar/' + employeeId + '/' + dateSearch)
      .pipe(
        map((data: Appointment[]) => {
          return data;
        }));
  }

  insertAppointment(appointment) {
    return this.http.post('api/appointment', appointment).pipe(
      map((data: any) => {
        return data;
      }));
  }

  updateAppointment(appointment) {
    return this.http.put('api/appointment', appointment).pipe(
      map((data: any) => {
        return data;
      }));
  }

  getTimeUsedForEmployee(timeUsedSearch) {
    return this.http.post('/api/appointment/timeUsed', timeUsedSearch)
      .pipe(
        map((data: any) => {
          return data;
        }));
  }

  copyAppointment(copyAppointment) {
    return this.http.post('/api/appointment/copy', copyAppointment)
      .pipe(
        map((data: any) => {
          return data;
        }));
  }

  deleteAppointment(appointmentId) {
    return this.http.delete('/api/appointment/' + appointmentId)
      .pipe(
        map((data: any) => {
          return data;
        }));
  }
}


