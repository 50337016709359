import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Lock} from '../models/lock';

@Injectable({
  providedIn: 'root'
})

export class LockService {
  constructor(private http: HttpClient) {
  }

  getLocks() {
    return this.http.get('/api/lock')
      .pipe(
        map((data: Lock[]) => {
          return data;
        }));
  }

  getSpecificMonth(month) {
    return this.http.get('/api/lock/' + month)
      .pipe(
        map((data: Lock) => {
          return data;
        }));
  }

  insertLock(lock) {
    return this.http.post('api/lock', lock).pipe(
      map((data: any) => {
        return data;
      }));
  }

  updateLock(lock) {
    return this.http.put('api/lock', lock).pipe(
      map((data: any) => {
        return data;
      }));
  }
}
