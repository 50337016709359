<div class="container mb-3">
  <div class="row">
    <div class="col-lg-6 mb-4">
      <div class="card card-body">
        <h2>Locked months</h2>
        <p *ngFor="let lock of locks">
          {{lock.date | danishDate: false}} -
          <button [disabled]="locked" *ngIf="lock.lockBy == null" type="submit" (click)="toggleMonth(lock)"
                  class="btn btn-primary mb-2">
            Lock
          </button>
          <button [disabled]="locked" *ngIf="lock.lockBy != null" type="submit" (click)="toggleMonth(lock)"
                  class="btn btn-warning mb-2">
            Unlock
          </button>
        </p>
        <p>
          {{lock |danishDate: false}}
          <button [disabled]="locked" type="submit" class="btn btn-primary mb-2" (click)="toggleMonth()">Lock</button>
        </p>
      </div>
    </div>
    <div class="col-lg-6 mb-4">
      <div class="card card-body">
        <h2>Reports</h2>
        <form [formGroup]='searchForm' class="standardModalForm" autocomplete="off">
          <div class="row">
            <div class="mb-3 col-md-6">
              <label for="startDate">Start date:</label>
              <input type="text" class="form-control" myDatepicker [onlyDatePicker]="true" formControlName="startDate"
                     name="{{randomName}}" id="startDate"
                     autocomplete="off" spellcheck="false"
                     tabindex="4" (blur)="customVal.logValidationErrors(this.searchForm, formErrors)">
              <span class="help-block alert-danger" *ngIf="formErrors.startDate">
                {{formErrors.startDate}}
                </span>
            </div>
            <div class="mb-3 col-md-6">
              <label for="endDate">End date:</label>
              <input type="text" class="form-control" myDatepicker [onlyDatePicker]="true" formControlName="endDate"
                     name="{{randomName}}" id="endDate"
                     autocomplete="off" spellcheck="false"
                     tabindex="4" (blur)="customVal.logValidationErrors(this.searchForm, formErrors)">
              <span class="help-block alert-danger" *ngIf="formErrors.endDate">
                {{formErrors.endDate}}
                </span>
            </div>
            <div class="col-md-12">
              <button type="submit" class="btn btn-primary mb-2" (click)="download()">Download</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
