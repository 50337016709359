import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'danishDate'})
export class DanishDatePipe implements PipeTransform {
  transform(value: any, datetime: boolean = true) {
    // Transform date
    if (value && value !== 'Invalid Date') {
      const splitted = String(value).split('-');
      if (splitted.length > 1) {
        const splittedTime = splitted[2].split('T');
        if (datetime) {
          return splittedTime[0] + '-' + splitted [1] + '-' + splitted [0] + ' ' + value.split('T')[1].split('+')[0];
        } else {
          return splittedTime[0] + '-' + splitted [1] + '-' + splitted [0];
        }
      }
    }
  }
}
