import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {EmployeeComponent} from '../employee/employee.component';
import {Employee} from '../../../../shared/models/employee/employee';
import {EmployeeService} from '../../../../shared/services/employee.service';
import {GlobalFunctionsService} from '../../../../shared/services/globalFunctions.service';
import {DialogService} from '../../../../shared/dialogs/dialog.service';
import {ToastrService} from 'ngx-toastr';
import {EmployeeGroup} from '../../../../shared/models/employee/employeeGroup';
import {CountryService} from '../../../../shared/services/country.service';
import {Country} from '../../../../shared/models/country';

@Component({
  selector: 'employeeView',
  templateUrl: 'employeeView.component.html',
  styleUrls: ['./employeeView.component.scss']
})

export class EmployeeViewComponent implements OnInit {
  constructor(public dialog: MatDialog,
              public globalFunctionsService: GlobalFunctionsService, private employeeService: EmployeeService,
              private dialogSrv: DialogService, private toastrService: ToastrService, private countryService: CountryService
  ) {
  }

  dataSource: MatTableDataSource<Employee>;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  displayedColumns = ['username', 'employeeGroupId', 'country', 'modifiedBy', 'modifiedOn', 'actions'];
  searchKey: string;
  public employeeGroups: EmployeeGroup[] = [];
  public countries: Country[] = [];

  ngOnInit() {
    this.load();
  }

  load() {
    this.employeeService.getEmployeeGroup().subscribe(
      success => {
        this.employeeGroups = success;
      });

    this.countryService.getCountry().subscribe(
      success => {
        this.countries = success;
      });

    this.employeeService.getEmployees().subscribe(
      success => {
        this.dataSource = new MatTableDataSource(success);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  getNameByEmployeeGroup(employeeGroupId) {
    const employeeGroup = this.employeeGroups.find(a => a.employeeGroupId === employeeGroupId);

    // fix to inactive groups

    if (!employeeGroup) {
      return ("Inaktiv Rolle");
    }

    return employeeGroup.name;
  }

  getNameByCountry(countryId) {
    return this.countries.find(a => a.countryId === countryId).name;
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLocaleLowerCase();
  }

  onSearchClear() {
    this.searchKey = '';
    this.applyFilter();
  }

  onCreate() {
    const dialogRef = this.dialog.open(EmployeeComponent, GlobalFunctionsService.matDialogConfigStandard());
    dialogRef.afterClosed().subscribe(() => {
      this.load();
    });
  }

  onEdit(employee) {
    const dialogRef = this.dialog.open(EmployeeComponent, GlobalFunctionsService.matDialogConfigStandard(employee));
    dialogRef.afterClosed().subscribe(() => {
      this.load();
    });
  }

  onDelete(row) {
    if (this.dialogSrv.modalOpen === false) {
      this.dialogSrv.modalOpen = true;
      this.dialogSrv.openConfirmDialog('Er du sikker på du vil slette ' + row.username, 'Sletning af bruger', 'Ja ', 'Annullere')
        .afterClosed().subscribe(res => {
        if (res) {
          this.employeeService.disableEmployee(row).subscribe(
            () => {
              this.load();
              this.toastrService.success('Deaktiveret: ' + row.username, 'Administration');
            }, err => {
              this.toastrService.error(err.error.message, err.error);
              console.log(err);
            }
          );
        }
        this.dialogSrv.modalOpen = false;
      });
    }
  }
}
