import {Input, ElementRef, Directive,} from '@angular/core';
import {DatePipe} from '@angular/common';

declare var jQuery: any;

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[myDatepicker]',
  providers: [DatePipe]
})

// tslint:disable-next-line:directive-class-suffix
export class DatePicker {
  @Input() onlyDatePicker = false;

  constructor(public el: ElementRef) {
  }

  // Jquery datetime picker
  // TODO VERY-LOW Remove jquery and find a angular timepicker there get's update and is nice
  ngOnInit() {
    if (this.onlyDatePicker === true) {
      jQuery(this.el.nativeElement).datepicker({
        timeFormat: 'HH:mm',
        dateFormat: 'yy-mm-dd',
        showAnim: 'slideDown',
        firstDay: 1,
        showWeek: true,
      }).on('change', function (e) {
        this.dispatchEvent(new Event('input'));
        this.focus();
      });
      /*jQuery(this.el.nativeElement).datepicker({
        timeFormat: 'HH:mm',
        dateFormat: 'dd-mm-yy',
        dayNamesMin: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun',
          'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        weekHeader: 'U',
        firstDay: 1,
        showAnim: 'slideDown',
        showWeek: true,
        currentText: 'Nu',
        closeText: 'Ok',
        timeText: 'Klokken',
        hourText: 'Timer',
        minuteText: 'Minutter',
      }).on('change', function (e) {
        this.dispatchEvent(new Event('input'));
        this.focus();
      });*/
    } else {
      jQuery(this.el.nativeElement).datetimepicker({
        timeFormat: 'HH:mm',
        dateFormat: 'yy-mm-dd',
        showAnim: 'slideDown',
        firstDay: 1,
        showWeek: true,
      }).on('change', function (e) {
        this.dispatchEvent(new Event('input'));
      });
      /*jQuery(this.el.nativeElement).datetimepicker({
        timeFormat: 'HH:mm',
        dateFormat: 'dd-mm-yy',
        dayNamesMin: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun',
          'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        weekHeader: 'U',
        firstDay: 1,
        showAnim: 'slideDown',
        showWeek: true,
        currentText: 'Nu',
        closeText: 'Ok',
        timeText: 'Klokken',
        hourText: 'Timer',
        minuteText: 'Minutter',
      }).on('change', function (e) {
        this.dispatchEvent(new Event('input'));
      });*/
    }
  }
}
