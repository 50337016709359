<datalist id="listEmployee">
  <ng-container *ngFor="let e of employees">
    <option *ngIf="e.active==true" [value]="e.username"></option>
  </ng-container>
</datalist>
<div class="row">
  <div class="col-lg-10 mb-4" style="z-index: 1;">
    <div class="card col itop-card py-2 m-0">
      <full-calendar id="calendar" [options]="calendarOptions" deepChangeDetection="true"
                     (select)="handleDateSelect($event)"
                     (drop)="handleEventDrop($event)"
                     (resize)="handleEventResize($event)"
                     (selectionchange)="handleDatesRender($event)"
      ></full-calendar>
    </div>
  </div>
  <div class="col-lg-2 mb-4" style="z-index: 2;">
    <div class="card col itop-card py-3">
      <div class="card-body">
        <select (change)="onEmployeeChange($event)" class="form-control" style="font-size: 12px"
                *ngIf="employeeGroupId == 1">
          <ng-container *ngFor="let e of employees">
            <ng-container *ngIf="e.active==true">
              <option *ngIf="e.employeeId==employeeId; else loggedOut" [value]="e.employeeId"
                      selected>{{e.username}}</option>
              <ng-template #loggedOut>
                <option [value]="e.employeeId">{{e.username}}</option>
              </ng-template>
            </ng-container>
          </ng-container>
        </select>
        <div><br>
        </div>
        <div class="row">
          <div class="col-3"><span class="circles"
                                   [ngStyle]="{'background-color': this.globalFunctionsService.colorProject}"></span>
          </div>
          <div class="col-9">Projekt</div>
        </div>
        <div class="row">
          <div class="col-3"><span class="circles"
                                   [ngStyle]="{'background-color': this.globalFunctionsService.colorAbsence}"></span>
          </div>
          <div class="col-9" style="white-space: nowrap;">Fravær</div>
        </div>
        <div class="row">
          <div class="col-3"><span class="circles"
                                   [ngStyle]="{'background-color': this.globalFunctionsService.colorByTheWay}"></span>
          </div>
          <div class="col-9">Øvrigt</div>
        </div>
        <div class="row">
          <div class="col-3"><span class="circles"
                                   [ngStyle]="{'background-color': this.globalFunctionsService.colorAdministrator}"></span>
          </div>
          <div class="col-9">Administration og økonomi</div>
        </div>

        <div style="height: 15px"></div>
        <!-- Time -->
        <div class="row">
          <!-- Monday -->
          <div class="col-3">Mon</div>
          <div class="col-9">{{timeUsed[0]}}</div>
          <!-- Tue -->
          <div class="col-3">Tue</div>
          <div class="col-9">{{timeUsed[1]}}</div>
          <!-- Wed -->
          <div class="col-3">Wed</div>
          <div class="col-9">{{timeUsed[2]}}</div>
          <!-- Thu -->
          <div class="col-3">Thu</div>
          <div class="col-9">{{timeUsed[3]}}</div>
          <!-- Fri -->
          <div class="col-3">Fri</div>
          <div class="col-9">{{timeUsed[4]}}</div>
          <!-- Sat -->
          <div class="col-3">Sat</div>
          <div class="col-9">{{timeUsed[5]}}</div>
          <!-- Sun -->
          <div class="col-3">Sun</div>
          <div class="col-9">{{timeUsed[6]}}</div>
        </div>
        <div style="height: 15px"></div>
        <div class="row">
          <!-- Total -->
          <div class="col-3">Week</div>
          <div class="col-9">{{timeUsed[7]}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
