import {AbstractControl, FormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';
import {Project} from '../models/project';

// This class is used for validating form groups and displaying a error message

@Injectable()
export class CustomValidators {

  // Error message
  static validationMessages = {
    'username': {
      'required': 'Must be completed',
    },
    'password': {
      'required': 'Must be completed',
      'passwordNotMatch': 'Password must be the same',
      'passwordPolicy': 'Password must be at least 8 characters, uppercase, lowercase and 1 number',
    },
    'password2': {
      'required': 'Must be completed',
    },
    'employeeGroup': {
      'required': 'Must be completed',
    },
    'country': {
      'required': 'Must be completed',
    },
    'date': {
      'required': 'Must be completed',
    },
    'duration': {
      'required': 'Must be completed',
      'invalidDurationTime': 'Invalid format use x.25/x.50/x.75 or whole numbers',
    },
    'kilometers': {
      'invalidDecimalNumber': 'Only numbers',
    },
    'description': {
      'required': 'Must be completed',
    },
    'mainCategory': {
      'required': 'Must be completed',
    },
    'sub1Category': {
      'required': 'Must be completed',
    },
    'sub2Category': {
      'required': 'Must be completed',
    },
    'project': {
      'required': 'Must be completed',
      'doesNotExists': 'The project does not exist',
    },
    'startDate': {
      'required': 'Must be completed',
    },
    'endDate': {
      'required': 'Must be completed',
    },
  };

  // Is set to true when submit is clicked
  public submitClicked;

  static selectProject(control: AbstractControl) {
    const val = control.value;
    if (val === 'Vælg') {
      return {'required': true};
    }
    return null;
  }

  static projectsExits(projects: Project[]) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value: string = control.value;
      if (value === '') {
        return null;
      }
      if (projects != null && value != null) {
        let projectExists = projects.filter(c => c.name === value);
        if (projectExists.length === 1) {
          return null;
        }
        projectExists = projects.filter(c => c.number === value);
        if (projectExists.length === 1) {
          return null;
        }
      }
      return {'doesNotExists': true};
    };
  }

  static passwordMatch(control: AbstractControl) {
    const pass = control.get('password').value;
    const confirmPass = control.get('password2').value;
    if (pass === confirmPass) {
      control.get('password').setErrors(null);
      // if (pass != null && pass !== '' && !pass.toString().match(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/)) {
      if (pass != null && pass !== '' && !pass.toString().match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
        control.get('password').setErrors({'passwordPolicy': true});
      }
      return null;
    } else {
      control.get('password').setErrors({'passwordNotMatch': true});
    }
  }

  static passwordMatch2(control: AbstractControl) {
    const pass = control.get('password').value;
    const confirmPass = control.get('password2').value;
    if (pass === confirmPass && pass !== '') {
      control.get('password').setErrors(null);
      // if (pass != null && pass !== '' && !pass.toString().match(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/)) {
      if (pass != null && pass !== '' && !pass.toString().match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
        control.get('password').setErrors({'passwordPolicy': true});
      }
      return null;
    } else {
      control.get('password').setErrors({'passwordNotMatch': true});
    }
  }

  static numberTime(control: AbstractControl) {
    const val = control.value;
    if (!val || val === '') {
      return null;
    }
    if (((val.toString().replace(',', '.') / 0.25) % 1) !== 0) {
      return {'invalidDurationTime': true};
    }
    return null;
  }

  static numberDecimal(control: AbstractControl) {
    const val = control.value;
    if (val === null || val === '') {
      return null;
    }
    // Comma
    if (!val.toString().match(/^[-+]?(?:[0-9])*[0-9]+(?:\,[0-9]+)?$/)) {
      // Dot
      if (!val.toString().match(/^[-+]?(?:[0-9])*[0-9]+(?:\.[0-9]+)?$/)) {
        return {'invalidDecimalNumber': true};
      }
    }
    return null;
  }

  // Validating all fields in form group and setting the correct error message
  public logValidationErrors(group: FormGroup, formErrors): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl, formErrors);
      } else {
        formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (this.submitClicked === true) ||
          (abstractControl.touched || abstractControl.dirty)) {
          const messages = CustomValidators.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              if (typeof messages === 'undefined') {
                console.log(key + ' har ingen error handling!!!');
              } else {
                formErrors[key] += messages[errorKey] + ' ';
              }
            }
          }
        }
      }
    });
  }
}
