import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {CountryService} from '../../shared/services/country.service';
import {GlobalFunctionsService} from '../../shared/services/globalFunctions.service';
import {FormBuilder, Validators} from '@angular/forms';
import {CustomValidators} from '../../shared/services/custom.validators';
import {CategoryService} from '../../shared/services/category.service';
import {CustomerService} from '../../shared/services/customer.service';
import {Category} from '../../shared/models/category';
import {Customer} from '../../shared/models/customer';
import {Appointment} from '../../shared/models/appointment';
import {Country} from '../../shared/models/country';
import {AppointmentInternalDescription} from '../../shared/models/appointmentInternalDescription';
import {Project} from '../../shared/models/project';
import {ProjectService} from '../../shared/services/project.service';
import {forkJoin, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AppointmentService} from '../../shared/services/appointment.service';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {LockService} from '../../shared/services/lock.service';
import {TimeUsed} from '../../shared/models/timeUsed';

@Component({
  selector: 'index',
  templateUrl: 'index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {
  spNumberValue: string;
  machineNumberValue: string;
  constructor(private countryService: CountryService, private categoryService: CategoryService, private customerService: CustomerService,
              private projectService: ProjectService, public globalFunctionsService: GlobalFunctionsService, public toastrService: ToastrService,
              private fb: FormBuilder, public customVal: CustomValidators, public appointmentService: AppointmentService, private datePipe: DatePipe,
              private lockService: LockService) {
  }

  public employeeId = GlobalFunctionsService.getEmployeeId();
  public categories: Category[] = [];
  public countries: Country[] = [];
  public customers: Customer[] = [];
  // public customerName;
  public country: Country;
  public customer: Customer;
  public randomName = GlobalFunctionsService.randomName;
  public marked = false;
  public mainCategory: Category;
  public subCategory: Category;
  public locked: boolean;
  public lockedMonth: boolean;
  public timeToday;

  @Output() messageEvent = new EventEmitter<string>();
  @Input() fromParentDate: string;
  @Input() fromParentDuration: string;
  @Input() fromParentAppointment: Appointment;

  // Projects
  public projects: Project[] = [];
  filteredOptions: Observable<string[]>;
  filteredOptionsName: Observable<string[]>;
  projectNames = [];
  projectId = [];

  public appointmentForm = this.fb.group({
    appointmentId: [''],
    createdOn: [''],
    createdBy: [''],
    modifiedOn: [''],
    modifiedBy: [''],
    active: [''],
    date: ['', [Validators.required]],
    duration: ['', [Validators.required, CustomValidators.numberTime]],
    fromLocation: [''],
    toLocation: [''],
    kilometers: ['', [CustomValidators.numberDecimal]],
    description: [''],
    internalDescription: [''],
  });

  public categoryForm = this.fb.group({
    mainCategory: [''],
    sub1Category: [''],
    sub2Category: [''],
    project: ['', [Validators.required]],
    projectName: [''],
  });

  formErrors = {
    'date': '',
    'duration': '',
    'fromLocation': '',
    'toLocation': '',
    'kilometers': '',
    'description': '',
    'internalDescription': '',
  };

  formCategoryErrors = {
    'mainCategory': '',
    'sub1Category': '',
    'sub2Category': '',
    'project': '',
    'projectName': ''
  };

  ngOnInit(): void {
    this.firstLoad();
    if (!this.fromParentAppointment) {
      this.load(false);
    }
  }

  private firstLoad() {
    this.lockedMonth = false;
    this.filteredOptions = this.categoryForm.valueChanges.pipe(startWith(''), map(val => this.filter(val)));
    this.filteredOptionsName = this.categoryForm.valueChanges.pipe(startWith(''), map(val => this.filterName(val)));

    if (this.fromParentAppointment) {
      this.appointmentForm.get('date').setValue(this.datePipe.transform(this.fromParentAppointment.date, 'yyyy-MM-dd HH:mm'));
      this.appointmentForm.get('duration').setValue(this.fromParentAppointment.duration.toString());
      this.appointmentForm.get('toLocation').setValue(this.fromParentAppointment.toLocation);
      this.appointmentForm.get('fromLocation').setValue(this.fromParentAppointment.fromLocation);
      this.appointmentForm.get('kilometers').setValue(this.fromParentAppointment.kilometers.toString());
      this.appointmentForm.get('description').setValue(this.fromParentAppointment.description);
      this.spNumberValue = this.fromParentAppointment.spNumber;
      // @ts-ignore
      this.machineNumberValue = this.fromParentAppointment.machineNumber;
      // TODO: ts ignore for machineNumber?
      this.country = this.fromParentAppointment.country;
      this.customer = this.fromParentAppointment.customer;

      this.lockService.getSpecificMonth(this.appointmentForm.value.date).subscribe(
        success => {
          if (success) {
            if (success.lockBy != null && GlobalFunctionsService.getEmployeeGroupId() !== 1) {
              this.lockedMonth = true;
            }
          }
        });

      if (this.fromParentAppointment.appointmentInternalDescription && this.fromParentAppointment.appointmentInternalDescription.length === 1) {
        this.appointmentForm.get('internalDescription').setValue(this.fromParentAppointment.appointmentInternalDescription[0].description);
      }
      this.getTimeOfDay();
    } else {

      this.categoryForm.get('mainCategory').setValue('Projekt');

      if (this.fromParentDate === '' || this.fromParentDate === undefined) {
        this.appointmentForm.get('date').setValue(this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm'));
      } else {
        this.appointmentForm.get('date').setValue(this.datePipe.transform(this.fromParentDate, 'yyyy-MM-dd HH:mm'));
        this.appointmentForm.get('duration').setValue(this.fromParentDuration);
      }
      this.getTimeOfDay();
    }

    let projectServiceMethod = this.globalFunctionsService.getEmployeeGroupId() === 3 ? this.projectService.getProjectsWithVKST() : this.projectService.getProjects();
    forkJoin([
      this.countryService.getCountry(),
      this.customerService.getCustomer(),
      projectServiceMethod]
    ).subscribe(([country, customers, projects]) => {
      // Country
      this.countries = country;
      if (this.fromParentAppointment === undefined) {
        this.country = this.countries.find(a => a.name === this.globalFunctionsService.getEmployeeCountry());
      }

      // Customers
      this.customers = customers;
      if (this.fromParentAppointment === undefined) {
        if (GlobalFunctionsService.getEmployeeGroupId() == 3) {
          this.customer = this.customers.find(a => a.name == 'VKST');
        } else {
          this.customer = this.customers[0];
        }
      }

      // Projects
      this.projects = [];
      this.projects = projects.sort(x => x.projectId);
      this.projectNames = [];
      this.projectId = [];
      this.categoryForm.get('project').setValidators([Validators.required, CustomValidators.projectsExits(this.projects)]);
      this.projects.forEach(value => {
        this.projectNames.push(value.name);
        this.projectId.push(value.number);
      });
      this.projectId = this.projectId.sort();
      this.projectNames = this.projectNames.sort();

      if (this.fromParentAppointment) {
        this.categoryService.getCategoryById(this.fromParentAppointment.categoryId).subscribe(
          success => {
            let sick;
            sick = success.categoryParentId === 20;
            this.categoryService.getCategory(sick).subscribe(
              successAll => {
                this.categories = successAll;
                const parent = success.categoryParent;
                if (parent) {
                  const subParent = parent.categoryParent;
                  if (subParent) {
                    this.categoryForm.get('mainCategory').setValue(subParent.name);
                    this.mainCategoryChange(subParent.name);
                    this.categoryForm.get('sub1Category').setValue(parent.name);
                    this.sub1CategoryChange(parent.name);
                    this.categoryForm.get('sub2Category').setValue(success.name);
                  } else {
                    this.categoryForm.get('mainCategory').setValue(parent.name);
                    this.mainCategoryChange(parent.name);
                    this.categoryForm.get('sub1Category').setValue(success.name);
                    this.sub1CategoryChange(success.name);
                  }
                } else {
                  this.categoryForm.get('mainCategory').setValue(success.name);
                  this.mainCategoryChange(success.name);
                  this.categoryForm.get('project').setValue(this.fromParentAppointment.project.number);
                  this.categoryForm.get('projectName').setValue(this.fromParentAppointment.project.name);
                }
              });
          });
      }
    });
  }

  load(sick) {
    this.categoryService.getCategory(sick).subscribe(
      success => {
        this.filteredOptions = this.categoryForm.valueChanges.pipe(startWith(''), map(val => this.filter(val)));
        this.filteredOptionsName = this.categoryForm.valueChanges.pipe(startWith(''), map(val => this.filterName(val)));
        this.categories = success;
        this.mainCategory = this.categories[0];
        this.subCategory = null;
        this.categoryForm.updateValueAndValidity({onlySelf: false, emitEvent: true});
      });
    let parent = this;
    setTimeout(function () {
      parent.categoryForm.updateValueAndValidity({onlySelf: false, emitEvent: true});
    }, 500);
  }

  mainCategoryChange(value) {
    this.mainCategory = this.categories.find(a => a.name == value);
    this.subCategory = null;
    this.categoryForm.get('sub1Category').setValue('Vælg');

    if (this.mainCategory.inverseCategoryParent.length > 0) {
      this.categoryForm.get('sub1Category').setValidators(CustomValidators.selectProject);
    } else {
      this.categoryForm.get('sub1Category').clearValidators();
    }
    if (this.mainCategory.categoryId === 1) {
      this.categoryForm.get('project').setValidators([Validators.required, CustomValidators.projectsExits(this.projects)]);
    } else {
      this.categoryForm.get('project').clearValidators();
    }

    this.categoryForm.get('sub2Category').clearValidators();
    this.categoryForm.get('sub2Category').updateValueAndValidity();
    this.categoryForm.get('sub1Category').updateValueAndValidity();
    this.categoryForm.get('project').updateValueAndValidity();
  }

  sub1CategoryChange(value) {
    this.categoryForm.get('sub2Category').setValue('Vælg');
    this.subCategory = this.mainCategory.inverseCategoryParent.find(a => a.name === value);
    this.categoryForm.get('sub2Category').clearValidators();
    this.categoryForm.get('sub2Category').setValue('Vælg');
    this.customVal.logValidationErrors(this.categoryForm, this.formCategoryErrors);

    if (this.subCategory.inverseCategoryParent.length > 0) {
      this.categoryForm.get('sub2Category').setValidators(CustomValidators.selectProject);
    } else {
      this.categoryForm.get('sub2Category').clearValidators();
    }

    this.categoryForm.get('sub2Category').updateValueAndValidity();
  }

  changeCountry(value) {
    this.country = this.countries.find(a => a.name === value.value);
  }

  updateCustomer(value: any) {
    this.customer = this.customers.find(cust => cust.customerId == value.target.value);
  }

  filter(val): string[] {
    if (val) {
      val = val.project;
    }
    return this?.projectId.filter(option => option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  filterName(val): string[] {
    if (val) {
      val = val.projectName;
    }
    return this.projectNames.filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  onCreate(create = true) {
    this.customVal.submitClicked = true;
    this.validateSpNumber();
    this.validateMachineNumber();
    this.customVal.logValidationErrors(this.appointmentForm, this.formErrors);
    this.customVal.logValidationErrors(this.categoryForm, this.formCategoryErrors);
    if (this.appointmentForm.valid && this.categoryForm.valid && this.spCheck() && this.machineCheck()) {
      this.locked = true;
      // Send message to output so if open in a modal it closes
      this.messageEvent.emit('buttonClicked');
      // Find categoryId
      let categoryChosenId;
      if (this.subCategory?.inverseCategoryParent.length > 0) {
        categoryChosenId = this.subCategory.inverseCategoryParent.find(a => a.name === this.categoryForm.value.sub2Category).categoryId;
      } else if (this.mainCategory?.inverseCategoryParent.length > 0) {
        categoryChosenId = this.mainCategory.inverseCategoryParent.find(a => a.name === this.categoryForm.value.sub1Category).categoryId;
      } else {
        categoryChosenId = this.mainCategory.categoryId;
      }
      // Find ProjectId if project is chosen
      let project = null;
      if (this.mainCategory.categoryId === 1) {
        project = this.projects.find(a => a.number === this.categoryForm.value.project);

      }
      if (create) {
        this.Create(categoryChosenId, project);
      } else {
        this.Update(categoryChosenId, project);
      }
      this.categoryForm = this.fb.group({
        mainCategory: [''],
        sub1Category: [''],
        sub2Category: [''],
        project: ['', [Validators.required]],
        projectName: [''],
      });

      this.firstLoad()

      setTimeout(() => {
        this.mainCategoryChange('Øvrigt');
      }, 100);

      setTimeout(() => {
        this.mainCategoryChange('Projekt');
      }, 200);



    }

  }

  Update(categoryChosenId, project) {
    const appointmentInternalDescriptions: AppointmentInternalDescription[] = [];
    if (this.fromParentAppointment.appointmentInternalDescription && this.fromParentAppointment.appointmentInternalDescription.length === 1) {
      this.fromParentAppointment.appointmentInternalDescription[0].description = this.appointmentForm.value.internalDescription;
      appointmentInternalDescriptions.push(this.fromParentAppointment.appointmentInternalDescription[0]);
    } else {
      if (this.appointmentForm.value.internalDescription != null && this.appointmentForm.value.internalDescription !== '') {
        const appointmentInternalDescription: AppointmentInternalDescription = {
          appointmentInternalDescriptionId: 0,
          createdBy: this.employeeId,
          createdOn: new Date(),
          modifiedBy: this.employeeId,
          modifiedOn: new Date(),
          active: true,
          appointmentId: 0,
          description: this.appointmentForm.value.internalDescription,
          employeeId: this.employeeId,
        };
        appointmentInternalDescriptions.push(appointmentInternalDescription);
      }
    }
    const appointment: Appointment = {
      appointmentId: this.fromParentAppointment.appointmentId,
      createdBy: this.fromParentAppointment.createdBy,
      createdOn: this.fromParentAppointment.createdOn,
      modifiedBy: this.fromParentAppointment.modifiedBy,
      modifiedOn: this.fromParentAppointment.modifiedOn,
      active: this.fromParentAppointment.active,
      categoryId: categoryChosenId,
      projectId: project ? project.projectId : null,
      countryId: this.country.countryId,
      customerId: this.customer?.customerId,
      date: new Date(this.appointmentForm.value.date),
      state: this.fromParentAppointment.state,
      kilometers: Number(this.appointmentForm.value.kilometers),
      fromLocation: this.appointmentForm.value.fromLocation,
      toLocation: this.appointmentForm.value.toLocation,
      employeeId: this.fromParentAppointment.employeeId,
      description: this.appointmentForm.value.description,
      duration: GlobalFunctionsService.commaToDot(this.appointmentForm.value.duration),
      spNumber: this.spNumberValue,
      machineNumber: this.machineNumberValue,
      appointmentInternalDescription: appointmentInternalDescriptions
    };

    this.appointmentService.updateAppointment(appointment).subscribe(
      () => {
        this.locked = false;
        this.toastrService.success('Updatede time', 'Time');
        this.customVal.submitClicked = false;
        this.appointmentForm.reset();
        this.appointmentForm.markAsPristine();
        this.appointmentForm.markAsUntouched();
        this.categoryForm.reset()
        this.spNumberError = "";
        this.spNumberValue = "";
        this.machineNumberError = "";
        this.machineNumberValue = "";
        this.categoryForm.markAsPristine();
        this.categoryForm.markAsUntouched();
        this.categoryForm.get('mainCategory').setValue('Projekt');
        this.appointmentForm.get('date').setValue(this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm'));
      },
      err => {
        this.locked = false;
        console.log(err);
      }
    );
  }

  Create(categoryChosenId, project) {
    const appointmentInternalDescriptions: AppointmentInternalDescription[] = [];
    this.lockService.getSpecificMonth(this.appointmentForm.value.date).subscribe(
      success => {
        if (success && success.lockBy != null && GlobalFunctionsService.getEmployeeGroupId() !== 1) {
          this.lockedMonth = true;
          this.locked = false;
        } else {
          if (this.appointmentForm.value.internalDescription != null && this.appointmentForm.value.internalDescription !== '') {
            const appointmentInternalDescription: AppointmentInternalDescription = {
              appointmentInternalDescriptionId: 0,
              createdBy: this.employeeId,
              createdOn: new Date(),
              modifiedBy: this.employeeId,
              modifiedOn: new Date(),
              active: true,
              appointmentId: 0,
              description: this.appointmentForm.value.internalDescription,
              employeeId: this.employeeId,
            };
            appointmentInternalDescriptions.push(appointmentInternalDescription);
          }

          const appointment: Appointment = {
            appointmentId: 0,
            createdBy: this.employeeId,
            createdOn: new Date(),
            modifiedBy: this.employeeId,
            modifiedOn: new Date(),
            active: true,
            categoryId: categoryChosenId,
            projectId: project ? project.projectId : null,
            countryId: this.country.countryId,
            customerId: this.customer?.customerId,
            date: new Date(this.appointmentForm.value.date),
            state: 0,
            kilometers: Number(this.appointmentForm.value.kilometers),
            fromLocation: this.appointmentForm.value.fromLocation,
            toLocation: this.appointmentForm.value.toLocation,
            employeeId: this.employeeId,
            description: this.appointmentForm.value.description,
            duration: GlobalFunctionsService.commaToDot(this.appointmentForm.value.duration),
            spNumber: this.spNumberValue,
            machineNumber: this.machineNumberValue,
            appointmentInternalDescription: appointmentInternalDescriptions
          };

          this.appointmentService.insertAppointment(appointment).subscribe(
            () => {
              this.locked = false;
              this.toastrService.success('Create time', 'Time');
              this.customVal.submitClicked = false;
              this.appointmentForm.reset();
              this.appointmentForm.markAsPristine();
              this.appointmentForm.markAsUntouched();
              this.categoryForm.reset();
              this.spNumberError = "";
              this.spNumberValue = "";
              this.machineNumberError = "";
              this.machineNumberValue = "";
              this.categoryForm.markAsPristine();
              this.categoryForm.markAsUntouched();
              this.categoryForm.get('mainCategory').setValue('Projekt');
              this.appointmentForm.get('date').setValue(this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm'));
            },
            err => {
              this.locked = false;
              console.log(err);
            }
          );
        }
      });
  }

  // Toggle view between normal and project
  spNumberError: string = "";
  machineNumberError: string = "";
  toggleVisibility(e) {
    this.categoryForm.get('sub1Category').setValue('Vælg');
    this.categoryForm.get('sub2Category').setValue('Vælg');
    this.marked = e.target.checked;
    if (this.marked) {
      this.categoryForm.get('sub1Category').setValidators(CustomValidators.selectProject);
      this.categoryForm.get('sub1Category').updateValueAndValidity();
      this.categoryForm.get('sub2Category').clearValidators();
      this.categoryForm.get('sub2Category').updateValueAndValidity();
      this.categoryForm.get('project').clearValidators();
      this.categoryForm.get('project').updateValueAndValidity();
      this.load(true);
    } else {
      this.categoryForm.get('project').setValidators([Validators.required, CustomValidators.projectsExits(this.projects)]);
      this.categoryForm.get('project').updateValueAndValidity();
      this.load(false);
    }
  }

  updateProject(name = false) {
    if (name) {
      const exists = this.projects.find(a => a.name === this.categoryForm.value.projectName);
      if (exists) {
        this.categoryForm.get('project').setValue(exists.number);
      } else {
        this.categoryForm.get('project').setValue('');
      }
    } else {
      const exists = this.projects.find(a => a.number === this.categoryForm.value.project);
      if (exists) {
        this.categoryForm.get('projectName').setValue(exists.name);
      } else {
        this.categoryForm.get('projectName').setValue('');
      }
    }
    this.customVal.logValidationErrors(this.categoryForm, this.formCategoryErrors);
  }

  getTimeOfDay() {
    const timeUsed: TimeUsed = {
      employeeId: this.employeeId,
      day: new Date(this.appointmentForm.value.date),
      week: false
    };

    this.appointmentService.getTimeUsedForEmployee(timeUsed).subscribe(
      success => {
        this.timeToday = success[0];
      });
  }

  validateSpNumber() {
    if (this.spNumberValue == null || this.spNumberValue.trim().length == 0 || this.spNumberValue.trim().length > 20) {
      this.spNumberError = "Must be completed (Max length is 20 characters)";
    } else {
      this.spNumberError = "";
    }
  }

  validateMachineNumber() {
    if (this.machineNumberValue == null || this.machineNumberValue.trim().length == 0 || this.machineNumberValue.trim().length > 10) {
      this.machineNumberError = "Must be completed (Max length is 10 characters)";
    } else {
      this.machineNumberError = "";
    }
  }

  spCheck() {
    if (this.mainCategory && this.mainCategory.categoryId == 1) {
      if (this.spNumberError === "") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  machineCheck() {
    if (this.mainCategory && this.mainCategory.categoryId == 2 && this.subCategory && this.subCategory.name == "Værksted") {
      if (this.machineNumberError === "") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

}
