<div class="container mb-3">
  <div class="row">
    <div class="col-sm-12 col-md-auto mb-4">
      <div class="pageTitle d-flex align-items-center">
        <h1 class="mb-0">Register time</h1>
        <div class="d-flex align-middle ms-3">
          <label [hidden]="globalFunctionsService.getEmployeeGroupId() == 3" class="switch">
            <input type="checkbox" id="togBtnNormalProject" [checked]="marked" (change)="toggleVisibility($event)">
            <div disabled class="slider round"><span class="on">Absence</span> <span class="off">Work</span></div>
          </label>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-auto">
      <div class="row">
        <div class="col col-md-auto" *ngIf="globalFunctionsService.getEmployeeGroupId() != 3">
          <div class="d-flex align-items-center">
            <img class="itop-mt--5 me-2" *ngIf="country != null" src="assets/flags/{{country?.name}}.png" alt="">
            <div class="mb-3 mb-0 mp-0 itop-mt--5">
              <mat-form-field>
                <mat-select (selectionChange)="changeCountry($event)" [value]="country?.name">
                  <mat-option *ngFor="let country of countries" [value]="country.name">
                    <img  *ngIf="country.name" src='assets/flags/{{country.name}}.png' alt=""> {{country.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col col-md-auto">
          <div  class="mb-3">
            <!--<label for="customer">Customer</label>-->
            <select *ngIf="globalFunctionsService.getEmployeeGroupId() != 3" class="form-control" id="customer"
                    (change)="updateCustomer($event)">
              <option disabled selected>Choose customer</option>
              <option *ngFor="let cust of customers" [value]="cust.customerId" [selected]="this.customer?.customerId == cust.customerId">{{cust.name}}</option>
            </select>
            <input class="form-control" *ngIf="globalFunctionsService.getEmployeeGroupId() == 3" disabled [value]="this.customer?.name">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4 mb-4">
      <div class="card card-body">
        <form [formGroup]='categoryForm' class="standardModalForm" autocomplete="off">
          <div class="mb-3">
            <label for="mainCategory">Main Category</label>
            <select *ngIf="globalFunctionsService.getEmployeeGroupId() != 3" [disabled]="true" class="form-control" id="mainCategory" (change)="mainCategoryChange($event.target.value)"
                    formControlName="mainCategory">
              <option *ngFor="let category of categories" [value]="category.name">{{category.name}}</option>
            </select>
            <input class="form-control" *ngIf="globalFunctionsService.getEmployeeGroupId() == 3" disabled readonly formControlName="mainCategory">
          </div>

          <!-- SubCategory 1 -->
          <div class="mb-3" *ngIf="mainCategory?.inverseCategoryParent.length > 0">
            <label for="sub1Category">Category2</label>
            <select class="form-control" id="sub1Category" (change)="sub1CategoryChange($event.target.value)"
                    formControlName="sub1Category" [ngClass]="{'is-invalid' : formCategoryErrors.sub1Category}">
              <option disabled selected>Vælg</option>
              <option *ngFor="let category of mainCategory?.inverseCategoryParent"
                      [value]="category.name">{{category.name}}</option>
            </select>
            <div class="invalid-feedback">
              {{formCategoryErrors.sub1Category}}
            </div>
          </div>

          <!-- SubCategory 2 -->
          <div class="mb-3" *ngIf="subCategory?.inverseCategoryParent.length > 0">
            <label for="sub2Category">Category 3</label>
            <select class="form-control" id="sub2Category"
                    formControlName="sub2Category" [ngClass]="{'is-invalid' : formCategoryErrors.sub2Category}">
              <option disabled selected>Vælg</option>
              <option *ngFor="let category of subCategory?.inverseCategoryParent"
                      [value]="category.name">{{category.name}}</option>
            </select>
            <div class="invalid-feedback">
              {{formCategoryErrors.sub2Category}}
            </div>
          </div>

          <!-- Projects -->
          <ng-container *ngIf="mainCategory && mainCategory.categoryId == 1">
            <div class="row">
              <div class="mb-3 col-md-12">
                <label for="project">Project:</label>
                <input id="project" class="form-control" formControlName="project" [matAutocomplete]="auto"
                       [ngClass]="{'is-invalid' : formCategoryErrors.project}"
                       #trigger="matAutocompleteTrigger"
                       (blur)="customVal.logValidationErrors(categoryForm, formCategoryErrors); updateProject(false);"
                       (keyup.enter)="customVal.logValidationErrors(categoryForm, formCategoryErrors); updateProject(false); trigger.closePanel();">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-autocomplete>
                <div class="invalid-feedback">
                  {{formCategoryErrors.project}}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-md-12">
                <label for="projectName">Project name:</label>
                <input id="projectName" class="form-control" formControlName="projectName" [matAutocomplete]="auto1"
                       [ngClass]="{'is-invalid' : formCategoryErrors.projectName}"
                       (blur)="customVal.logValidationErrors(categoryForm, formCategoryErrors); updateProject(true)"
                       (keyup.enter)="customVal.logValidationErrors(categoryForm, formCategoryErrors); updateProject(true);">
                <mat-autocomplete #auto1="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptionsName | async" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-autocomplete>
                <div class="invalid-feedback">
                  {{formCategoryErrors.projectName}}
                </div>
              </div>
            </div>

          </ng-container>
        </form>

        <ng-container *ngIf="mainCategory && mainCategory.categoryId == 1">
            <!-- SP Number -->

            <div class="row">
              <div class="mb-3 col-md-12">
                <label for="spNumber">SP-nummer</label>
                <input id="spNumber" autocomplete="off" class="form-control" tabindex="1" name="{{randomName}}" [(ngModel)]="this.spNumberValue"
                       (blur)="validateSpNumber()"
                       (focusout)="validateSpNumber()"
                       [ngClass]="{'is-invalid' : spNumberError}">
                <div class="invalid-feedback">
                  {{spNumberError}}
                </div>
              </div>
            </div>

          </ng-container>

        <ng-container *ngIf="mainCategory && mainCategory.categoryId == 2 && subCategory && subCategory.name == 'Værksted'">
          <!-- Machine Number -->

          <div class="row">
            <div class="mb-3 col-md-12">
              <label for="machineNumber">maskine-nummer</label>
              <input id="machineNumber" autocomplete="off" class="form-control" tabindex="1" name="{{randomName}}" [(ngModel)]="this.machineNumberValue"
                     (blur)="validateMachineNumber()"
                     (focusout)="validateMachineNumber()"
                     [ngClass]="{'is-invalid' : machineNumberError}">
              <div class="invalid-feedback">
                {{machineNumberError}}
              </div>
            </div>
          </div>

        </ng-container>

      </div>
    </div>

    <div class="col-lg-8 mb-4">
      <div class="card card-body">
        <form [formGroup]='appointmentForm' class="standardModalForm" autocomplete="off">
          <div class="row">
            <div class="mb-3 col-md-6">
              <label for="date">Date:
                <span style="font-size: 10px; padding-left: 30px">Time today ({{timeToday}})</span>
              </label>
              <input type="text" class="form-control" myDatepicker [onlyDatePicker]="false" formControlName="date"
                     name="{{randomName}}" id="date"
                     autocomplete="off" spellcheck="false"
                     tabindex="4"
                     (blur)="customVal.logValidationErrors(this.appointmentForm, formErrors); getTimeOfDay()">
              <span class="help-block alert-danger" *ngIf="formErrors.date">
                {{formErrors.date}}
                </span>
            </div>
            <div class="mb-3 col-md-6">
              <label for="duration">Duration: (hours)</label>
              <input type="text" class="form-control" formControlName="duration" name="{{randomName}}"
                     id="duration" autocomplete="off" tabindex="1" [ngClass]="{'is-invalid' : formErrors.duration}"
                     (blur)="customVal.logValidationErrors(appointmentForm, formErrors)">
              <div class="invalid-feedback">
                {{formErrors.duration}}
              </div>
            </div>
            <div class="mb-3 col-md-12" style="height: 1.5rem;">
              <h3>Driving</h3><br><br>
            </div>
            <div class="mb-3 col-md-2">
              <label for="kilometers">Km:</label>
              <input type="text" class="form-control" formControlName="kilometers" name="{{randomName}}"
                     id="kilometers" autocomplete="off" tabindex="1" [ngClass]="{'is-invalid' : formErrors.kilometers}"
                     (blur)="customVal.logValidationErrors(appointmentForm, formErrors)">
              <div class="invalid-feedback">
                {{formErrors.kilometers}}
              </div>
            </div>
            <div class="mb-3 col-md-5">
              <label for="toLocation">To:</label>
              <input type="text" class="form-control" formControlName="toLocation" name="{{randomName}}"
                     id="toLocation" autocomplete="off" tabindex="1" [ngClass]="{'is-invalid' : formErrors.toLocation}"
                     (blur)="customVal.logValidationErrors(appointmentForm, formErrors)">
              <div class="invalid-feedback">
                {{formErrors.toLocation}}
              </div>
            </div>
            <div class="mb-3 col-md-5">
              <label for="fromLocation">From:</label>
              <input type="text" class="form-control" formControlName="fromLocation" name="{{randomName}}"
                     id="fromLocation" autocomplete="off" tabindex="1"
                     [ngClass]="{'is-invalid' : formErrors.fromLocation}"
                     (blur)="customVal.logValidationErrors(appointmentForm, formErrors)">
              <div class="invalid-feedback">
                {{formErrors.fromLocation}}
              </div>
            </div>
          </div>
          <div class="row">
            <ng-container *ngIf="globalFunctionsService.getEmployeeGroupId() != 3; else inputField">
              <div class="mb-3 col-md-6">
                <label for="description">Description:</label>
                <textarea id="description" class="w-100 form-control" formControlName="description" rows="4"
                          [ngClass]="{'is-invalid' : formErrors.description}"
                          (blur)="customVal.logValidationErrors(appointmentForm, formErrors)"></textarea>
                <div class="invalid-feedback">
                  {{formErrors.description}}

                </div>
              </div>
            </ng-container>
            <ng-template #inputField>
              <div class="mb-3 col-md-6">
                <label for="description">Work description:</label>
                <select required id="description2" class="w-100 form-control" formControlName="description"
                        [ngClass]="{'is-invalid' : formErrors.description}"
                        (blur)="customVal.logValidationErrors(appointmentForm, formErrors)">
                  <option disabled value="">Select an option</option>
                  <option value="Dataindsamling / registreringer">Dataindsamling / registreringer</option>
                  <option value="Markarbejde manuelt">Markarbejde manuelt</option>
                  <option value="Markarbejde maskine">Markarbejde maskine</option>
                  <option value="Værksted / udvikling">Værksted / udvikling</option>
                </select>
                <div class="invalid-feedback">
                  {{formErrors.description}}
                </div>
              </div>
            </ng-template>
            <div class="mb-3 col-md-6" *ngIf="globalFunctionsService.getEmployeeGroupId() != 3">
              <label for="internalDescription">Internal description:</label>
              <textarea id="internalDescription" class="w-100 form-control" formControlName="internalDescription" rows="4"></textarea>
            </div>
          </div>
          <button type="submit" class="btn btn-primary" *ngIf="fromParentAppointment == undefined && spCheck() && machineCheck()" (click)="onCreate()"
                  [disabled]="locked || lockedMonth" tabindex="5">
            Create
          </button>
          <button type="submit" class="btn btn-primary" *ngIf="fromParentAppointment != undefined && spCheck() && machineCheck()"
                  (click)="onCreate(false)" [disabled]="locked || lockedMonth" tabindex="5">
            Update
          </button>
          <div>
            <p *ngIf="lockedMonth" style="color: red;">This month is locked and therefore can not be changed</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
