<div class="container">
  <div class="row">
    <div class="col mb-4">
      <div class="pageTitle d-flex align-items-center">
        <h2 *ngIf="employeeForm.get('employeeId').value == 0">Create user</h2>
        <h2 *ngIf="employeeForm.get('employeeId').value != 0">Update user</h2>
      </div>
    </div>
  </div>
  <form [formGroup]='employeeForm' class="standardModalForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="row">
      <div class="mb-3 col-md-4">
        <label for="username">Username:</label>
        <input type="text" class="form-control" formControlName="username" name="{{randomName}}"
               id="username" autocomplete="off" tabindex="1" [ngClass]="{'is-invalid' : formErrors.username}"
               (blur)="customVal.logValidationErrors(employeeForm, formErrors)">
        <div class="invalid-feedback">
          {{formErrors.username}}
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="password">Password:</label>
        <input type="password" class="form-control" formControlName="password" name="{{randomName}}"
               id="password" autocomplete="off" tabindex="2" [ngClass]="{'is-invalid' : formErrors.password}"
               (blur)="customVal.logValidationErrors(employeeForm, formErrors)">
        <div class="invalid-feedback">
          {{formErrors.password}}
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="password2">Repeat password:</label>
        <input type="password" class="form-control" formControlName="password2" name="{{randomName}}"
               id="password2" autocomplete="off" tabindex="3" [ngClass]="{'is-invalid' : formErrors.password2}"
               (blur)="customVal.logValidationErrors(employeeForm, formErrors)">
        <div class="invalid-feedback">
          {{formErrors.password2}}
        </div>
      </div>

      <div class="mb-3 col-md-4">
        <label for="employeeGroup">Role:</label>
        <select class="form-control" id="employeeGroup" formControlName="employeeGroup" tabindex="4"
                [ngClass]="{'is-invalid' : formErrors.employeeGroup}">
          <option *ngFor="let employeeGroup of employeeGroups"
                  [value]="employeeGroup.name">{{employeeGroup.name}}</option>
        </select>
        <div class="invalid-feedback">
          {{formErrors.employeeGroup}}
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="country">Country:</label>
        <select class="form-control" id="country" formControlName="country" tabindex="5"
                [ngClass]="{'is-invalid' : formErrors.country}">
          <option *ngFor="let country of countries"
                  [value]="country.name">{{country.name}}</option>
        </select>
        <div class="invalid-feedback">
          {{formErrors.country}}
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-primary mb-3" tabindex="5" id="submit">
      <span *ngIf="employeeForm.get('employeeId').value == 0">Create user</span><span
      *ngIf="employeeForm.get('employeeId').value != 0">Update user</span>
    </button>
  </form>
</div>
