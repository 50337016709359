import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Country} from '../models/country';

@Injectable({
  providedIn: 'root'
})

export class CountryService {
  constructor(private http: HttpClient) {
  }

  getCountry() {
    return this.http.get('/api/country')
      .pipe(
        map((data: Country[]) => {
          return data;
        }));
  }
}
