import {Injectable} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';
import {Employee} from '../models/employee/employee';


@Injectable()
export class GlobalFunctionsService {
  // Create a random name so google can't autofill
  static randomName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  // All employees for easing getting name from id
  static employees: Employee[] = [];

  // Font awesome icons
  public closeIcon = 'fas fa-times';
  public plusIcon = 'fas fa-plus';
  public editIcon = 'fas fa-pen';
  public deleteIcon = 'fas fa-trash-alt';

  // Calendar colors
  public colorProject = '#16C890';
  public colorAbsence = '#C6345E';
  public colorByTheWay = '#364860';
  public colorAdministrator = '#34A0c2';

  // Table sort size and options
  public pageSizeOptions = [200, 500, 1000];
  public pageSize = 200;

  static getEmployeeId() {
    if (localStorage.getItem('token')) {
      const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
      return Number(token.EmployeeId);
    }
  }

  static getEmployeeUsername() {
    if (localStorage.getItem('token')) {
      const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
      return token.Username;
    }
  }

  static getEmployeeGroupId() {
    if (localStorage.getItem('token')) {
      const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
      return Number(token.EmployeeGroupId);
    }
  }

  // Remove timezone
  static removeTimezoneFromDateTime(dateTime) {
    dateTime.setHours(dateTime.getHours() + Math.abs(dateTime.getTimezoneOffset() / 60));
    return dateTime.toISOString().slice(0, -2);
  }

  // Standard config of a dialog
  static matDialogConfigStandard(data = null): any {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '50%';
    dialogConfig.maxHeight = '91vh';
    dialogConfig.data = data;
    return dialogConfig;
  }

  // Message when dialogBackDrop is clicked
  static dialogBackDropClick() {
    return !confirm('Er du sikker på du vil lukke modal');
  }

  // Check if modal is dirty and support escape
  static dialogCloseConfirm(dialogRef, form) {
    dialogRef.backdropClick().subscribe(() => {
      if (form.dirty) {
        if (GlobalFunctionsService.dialogBackDropClick()) {
          return false;
        }
      }
      dialogRef.close();
    });

    dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        dialogRef.close();
      }
    });
  }

  // Convert , to .
  static commaToDot(string) {
    if (string != null) {
      return string.toString().replace(',', '.');
    }
  }

  // Convert . to ,
  static dotToComma(string) {
    if (string != null) {
      return string.toString().replace('.', ',');
    }
  }

  public getEmployeeId() {
    if (localStorage.getItem('token')) {
      const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
      return Number(token.EmployeeId);
    }
  }

  public getEmployeeUsername() {
    if (localStorage.getItem('token')) {
      const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
      return token.Username;
    }
  }

  public getEmployeeGroupId() {
    if (localStorage.getItem('token')) {
      const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
      return Number(token.EmployeeGroupId);
    }
  }

  public getEmployeeById(employeeId): Employee {
    if (GlobalFunctionsService.employees) {
      return GlobalFunctionsService.employees.find(a => a.employeeId === employeeId);
    }
  }

  public getEmployeeCountry() {
    if (localStorage.getItem('token')) {
      const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
      return token.CountryName;
    }
  }

  public findEmployeeNameFromId(id) {
    if (GlobalFunctionsService.employees && GlobalFunctionsService.employees.length > 0) {
      return GlobalFunctionsService.employees.find(a => a.employeeId === id).username;
    }
  }
}
