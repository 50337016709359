import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {CustomValidators} from '../../../../shared/services/custom.validators';
import {EmployeeService} from '../../../../shared/services/employee.service';
import {GlobalFunctionsService} from '../../../../shared/services/globalFunctions.service';
import {EmployeeGroup} from '../../../../shared/models/employee/employeeGroup';
import {Employee} from '../../../../shared/models/employee/employee';
import {CountryService} from '../../../../shared/services/country.service';
import {Country} from '../../../../shared/models/country';

@Component({
  selector: 'employee',
  templateUrl: 'employee.component.html',
  styleUrls: ['./employee.component.scss']
})

export class EmployeeComponent implements OnInit {
  constructor(private fb: FormBuilder, private router: Router, public dialog: MatDialog,
              private route: ActivatedRoute, public dialogRef: MatDialogRef<EmployeeComponent>,
              @Inject(MAT_DIALOG_DATA) public employee, public globalFunctionsService: GlobalFunctionsService,
              public customVal: CustomValidators, private employeeService: EmployeeService,
              private toastrService: ToastrService, private countryService: CountryService
  ) {
  }

  public employeeId = GlobalFunctionsService.getEmployeeId();
  public randomName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  public employeeGroups: EmployeeGroup[] = [];
  public countries: Country[] = [];
  public employeeForm;

  formErrors = {
    'username': '',
    'employeeGroup': '',
    'password': '',
    'password2': '',
    'country': '',
  };

  ngOnInit() {
    this.countryService.getCountry().subscribe(
      success => {
        this.countries = success;
      });

    if (this.employee) {
      this.employeeForm = this.fb.group({
        employeeId: [''],
        createdOn: [''],
        createdBy: [''],
        modifiedOn: [''],
        modifiedBy: [''],
        active: [''],
        employeeGroup: ['', [Validators.required]],
        country: ['', [Validators.required]],
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
        password2: [''],
      }, {validator: CustomValidators.passwordMatch});
    } else {
      this.employeeForm = this.fb.group({
        employeeId: [''],
        createdOn: [''],
        createdBy: [''],
        modifiedOn: [''],
        modifiedBy: [''],
        active: [''],
        employeeGroup: ['', [Validators.required]],
        country: ['', [Validators.required]],
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
        password2: [''],
      }, {validator: CustomValidators.passwordMatch2});
    }

    GlobalFunctionsService.dialogCloseConfirm(this.dialogRef, this.employeeForm);
    if (this.employee) {
      this.employeeForm.setValue({
        employeeId: this.employee.employeeId,
        createdOn: this.employee.createdOn,
        createdBy: this.employee.createdBy,
        modifiedOn: this.employee.modifiedOn,
        modifiedBy: this.employee.modifiedBy,
        active: this.employee.active,
        employeeGroup: this.employee.employeeGroup.name,
        username: this.employee.username,
        country: this.employee.country.name,
        password: null,
        password2: null,
      });
    }
    this.employeeService.getEmployeeGroup()
      .subscribe(success => {
        this.employeeGroups = success;
      });
  }

  onSubmit() {
    this.customVal.submitClicked = true;
    this.customVal.logValidationErrors(this.employeeForm, this.formErrors);
    if (this.employeeForm.valid) {
      if (this.employee) {
        const employee: Employee = {
          employeeId: this.employeeForm.value.employeeId,
          createdBy: this.employeeForm.value.createdBy,
          createdOn: this.employeeForm.value.createdOn,
          modifiedBy: this.employeeId,
          modifiedOn: new Date(),
          active: true,
          username: this.employeeForm.value.username,
          password: this.employeeForm.value.password,
          countryId: (this.countries.find(s => s.name === this.employeeForm.value.country).countryId),
          employeeGroupId: (this.employeeGroups.find(s => s.name === this.employeeForm.value.employeeGroup).employeeGroupId),
        };
        this.employeeService.updateEmployee(employee).subscribe(
          () => {
            this.toastrService.success('Updated user ' + this.employeeForm.value.username, 'User');
            this.dialogRef.close();
          },
          err => {
            this.toastrService.error('There already exists a user with that username', 'User');
          }
        );
      } else {
        const employee: Employee = {
          employeeId: 0,
          createdBy: this.employeeId,
          createdOn: new Date(),
          modifiedBy: this.employeeId,
          modifiedOn: new Date(),
          active: true,
          username: this.employeeForm.value.username,
          password: this.employeeForm.value.password,
          countryId: (this.countries.find(s => s.name === this.employeeForm.value.country).countryId),
          employeeGroupId: (this.employeeGroups.find(s => s.name === this.employeeForm.value.employeeGroup).employeeGroupId),
        };
        this.employeeService.insertEmployee(employee).subscribe(
          () => {
            this.toastrService.success('Updated user ' + this.employeeForm.value.username, 'User');
            this.dialogRef.close();
          },
          err => {
            this.toastrService.error('Username is already used', 'User');
          }
        );
      }
    }
  }
}
