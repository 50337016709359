import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ToastrService} from 'ngx-toastr';
import {Component, OnInit} from '@angular/core';
import {CustomValidators} from '../../../shared/services/custom.validators';
import {EmployeeService} from '../../../shared/services/employee.service';
import {GlobalFunctionsService} from '../../../shared/services/globalFunctions.service';
import {Employee} from '../../../shared/models/employee/employee';

@Component({
  selector: 'employeePasswordComponent',
  templateUrl: 'employeePassword.component.html',
  styleUrls: ['./employeePassword.component.scss']

})
export class EmployeePasswordComponent implements OnInit {


  constructor(private fb: FormBuilder, private toastr: ToastrService,
              public customVal: CustomValidators,
              public globalFunctionsService: GlobalFunctionsService,
              private employeeService: EmployeeService) {
  }

  employeePasswordForm: FormGroup;

  public randomName = GlobalFunctionsService.randomName;
  public employeeId = GlobalFunctionsService.getEmployeeId();
  public username = GlobalFunctionsService.getEmployeeUsername();

  formErrors = {
    'password': '',
    'password2': '',
  };

  ngOnInit() {
    this.employeePasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      password2: [''],
      employeeId: [''],
    }, {validator: CustomValidators.passwordMatch});
  }

  onSubmit() {
    this.customVal.submitClicked = true;
    this.customVal.logValidationErrors(this.employeePasswordForm, this.formErrors);
    if (this.employeePasswordForm.valid) {
      const employee: Employee = {
        employeeId: this.employeeId,
        createdBy: this.employeeId,
        createdOn: new Date(),
        modifiedBy: this.employeeId,
        modifiedOn: new Date(),
        active: true,
        username: null,
        employeeGroupId: null,
        countryId: null,
        password: this.employeePasswordForm.value.password,
      };

      this.employeeService.updateEmployee(employee).subscribe(
        () => {
          this.toastr.success('Updated password', 'User');
          this.employeePasswordForm.reset();
        }, err => {
          this.toastr.error(err.error.message, err.error);
          console.log(err);
        }
      );
    }
  }
}
